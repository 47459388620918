import { filterCategoryNames } from '@/config/filter';
import { State } from '@/config/state';
import type {
  FilterCategoryName,
  FilterCategoryWithFacets,
} from '@/types/list-page';

const usePreselectedFilters = () => {
  const route = useRoute();

  const preselectedFilters = useState<FilterCategoryWithFacets>(State.PreselectedFilters);
  const areInitialFiltersSetViaSearchParams = useState<boolean>(State.ArePreselectedFiltersCustomized, () => true);

  const getAreInitialFiltersSetViaSearchParams = computed(() => areInitialFiltersSetViaSearchParams.value);

  const getPreselectedFilters = computed(() => preselectedFilters.value);

  const setPreselectedFilters = (filters?: FilterCategoryWithFacets) => {
    const { query } = route;

    const shouldUseSearchParams = searchParamsIncludeFilter(query);

    if (!shouldUseSearchParams && filters) {
      preselectedFilters.value = filters;
      areInitialFiltersSetViaSearchParams.value = false;

      return;
    }

    const filteredQuery = Object.keys(query)
      .filter((key): key is FilterCategoryName => filterCategoryNames.includes(key as FilterCategoryName))
      .reduce((acc, key) => {
        const filteredValues = ensureValuesAreArrays({ [key]: query[key] });
        acc[key] = filteredValues[key];
        return acc;
      }, {} as FilterCategoryWithFacets);

    preselectedFilters.value = filteredQuery;
  };

  return {
    getAreInitialFiltersSetViaSearchParams,
    getPreselectedFilters,
    setPreselectedFilters,
  };
};

export { usePreselectedFilters };
