type ValuesToCheckForEmpty = null | undefined | object | string;

const isEmpty = (value: ValuesToCheckForEmpty) => (
  // From standard.js: Always use === - but
  // obj == null is allowed to check null || undefined
  value == null
    || (typeof value === 'object' && Object.keys(value).length === 0)
    || (typeof value === 'string' && value.trim().length === 0)
);

export { isEmpty };
