const HITS_PER_PAGE = 40;

const sortingIndex = [
  'bestseller',
  'latest',
  'relevancy',
] as const;

type SortingIndex = typeof sortingIndex[number];

const isValidSortingIndex = (value: string | null | undefined): value is SortingIndex => {
  const validValues = (Object.values<string>(sortingIndex));
  return !!value && validValues.includes(value);
};

export {
  HITS_PER_PAGE,
  isValidSortingIndex,
  sortingIndex,
  type SortingIndex,
};
