import {
  isValidSortingIndex,
  type SortingIndex,
} from '@/config/designs';
import { State } from '@/config/state';

const useSortingParameter = () => {
  const route = useRoute();
  const router = useRouter();

  const sorting = useState<SortingIndex>(State.Sorting);

  const initSorting = (preselectedSorting: SortingIndex | '' | undefined) => {
    const searchParams = ensureArray(route.query.sorting);
    sorting.value = searchParams.find(isValidSortingIndex) || preselectedSorting || 'relevancy';
  };

  const updateSorting = (sortingValue: SortingIndex) => {
    sorting.value = sortingValue;

    router.replace({
      query: {
        ...route.query,
        sorting: sortingValue,
      },
    });
  };

  const getSorting = computed(() => sorting.value);

  return {
    getSorting,
    initSorting,
    updateSorting,
  };
};

export { useSortingParameter };
