const useClientCache = () => {
  const nuxtApp = useNuxtApp();
  const MILLISECONDS_IN_SECOND = 1000;

  const setClientCacheItem = (key: string, value: any, maxAge?: number) => {
    if (import.meta.client) {
      nuxtApp.static.data[key] = {
        data: value,
        expires: maxAge
          ? Date.now() + maxAge * MILLISECONDS_IN_SECOND
          : null,
      };
    }
  };

  const getClientCacheItem = <T>(key: string): T | null => {
    if (import.meta.client) {
      const cacheItem = nuxtApp.static.data[key];
      if (!cacheItem) {
        return null;
      }

      if (cacheItem.expires && cacheItem.expires < Date.now()) {
        delete nuxtApp.static.data[key];
        return null;
      }

      return cacheItem.data;
    }

    return null;
  };

  const clearClientCacheItem = (key: string) => {
    delete nuxtApp.static.data[key];
  };

  return {
    clearClientCacheItem,
    getClientCacheItem,
    setClientCacheItem,
  };
};

export { useClientCache };
