<script setup lang="ts">
import { FilterIcon } from 'lucide-vue-next';

const {
  getIsFiltersListHidden,
  getIsFiltersListHiddenMobile,
  setIsFiltersListHidden,
} = useActiveFilters();

const { isBpDesktop } = useAppBreakpoints();

const translationKeys = {
  hideFilters: 'designListing.hideFilters',
  showFilters: 'designListing.showFilters',
};

const filterVisibilityToUpdate = computed(() => (isBpDesktop.value
  ? getIsFiltersListHidden.value
  : getIsFiltersListHiddenMobile.value));
</script>

<template>
  <Button
    class="filter-button mr-4 w-full"
    type="button"
    @click="setIsFiltersListHidden(!filterVisibilityToUpdate)"
  >
    <FilterIcon class="size-4" />
    <div class="block md:hidden">
      <span v-text="$t(getIsFiltersListHiddenMobile ? translationKeys.showFilters : translationKeys.hideFilters)" />
    </div>
    <div class="hidden md:block">
      <span v-text="$t(getIsFiltersListHidden ? translationKeys.showFilters : translationKeys.hideFilters)" />
    </div>
  </Button>
</template>
