<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'lucide-vue-next';

enum Pagination {
  TextBreakpoint = '...',
  TextFirstPage = '1',
}

withDefaults(defineProps<{
  itemsPerPage: number;
  maxPagesShown: number;
  totalItems: number;
}>(), { itemsPerPage: 10,maxPagesShown: 3, })

const { currentPage } = usePagination();

const totalPages = computed(() => Math.ceil(__props.totalItems / __props.itemsPerPage));

const paginate = computed(() => {
  let startPage: number;
  let endPage: number;
  // if total pages are less than maximum pages to be displayed (maxPagesShown), then show all pages
  if (totalPages.value <= __props.maxPagesShown) {
    startPage = 1;
    endPage = totalPages.value;
  } else {
    // calculating start and end pages

    const halfOfMaxPagesShown = __props.maxPagesShown / 2;
    const maxPagesShownBeforeCurrentPage = Math.floor(halfOfMaxPagesShown);
    const maxPagesShownAfterCurrentPage = Math.ceil(halfOfMaxPagesShown) - 1;
    if (currentPage.value <= maxPagesShownBeforeCurrentPage) {
      // current page is at the start of the pagination
      startPage = 1;
      endPage = __props.maxPagesShown;
    } else if (currentPage.value + maxPagesShownAfterCurrentPage >= totalPages.value) {
      // current page is at the end of the pagination
      startPage = totalPages.value - __props.maxPagesShown + 1;
      endPage = totalPages.value;
    } else {
      // current page is somewhere in the middle of the pagination
      startPage = currentPage.value - maxPagesShownBeforeCurrentPage;
      endPage = currentPage.value + maxPagesShownAfterCurrentPage;
    }
  }
  // create an array of pages to be displayed
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i,
  );

  return {
    endPage,
    pages,
    startPage,
  };
});

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const startingBreakPointButtonIfCondition = computed(() => paginate.value.pages[0] >= 3);
const endingBreakPointButtonIfCondition = computed(() => paginate.value.pages[paginate.value.pages.length - 1] < totalPages.value - 1);

const firstButtonIfCondition = computed(() => paginate.value.pages[0] >= 2);
const lastButtonIfCondition = computed(() => paginate.value.pages[paginate.value.pages.length - 1] < totalPages.value);
</script>

<template>
  <ul class="pagination-container ml-0 flex list-none justify-center gap-x-1">
    <!-- Back Button -->
    <PaginationButton
      :is-disabled="currentPage === 1"
      :page-number="currentPage - 1"
      :total-pages="totalPages"
    >
      <ArrowLeftIcon class="size-4" />
    </PaginationButton>

    <!-- First Button before Starting Breakpoint Button -->
    <PaginationButton
      v-if="firstButtonIfCondition"
      :page-number="1"
      :total-pages="totalPages"
    >
      {{ Pagination.TextFirstPage }}
    </PaginationButton>

    <!-- Starting Breakpoint Button -->
    <PaginationButton
      v-if="startingBreakPointButtonIfCondition"
      :is-clickable="false"
      :page-number="currentPage - Math.ceil(maxPagesShown / 2)"
      :total-pages="totalPages"
    >
      {{ Pagination.TextBreakpoint }}
    </PaginationButton>

    <!-- Numbers Buttons -->
    <PaginationButton
      v-for="(page, index) in paginate.pages"
      :key="index"
      :is-active="page === currentPage"
      :page-number="page"
      :total-pages="totalPages"
    >
      {{ page }}
    </PaginationButton>

    <!-- Ending Breakpoint Button -->
    <PaginationButton
      v-if="endingBreakPointButtonIfCondition"
      :is-clickable="false"
      :page-number="currentPage - Math.ceil(maxPagesShown / 2)"
      :total-pages="totalPages"
    >
      {{ Pagination.TextBreakpoint }}
    </PaginationButton>

    <!-- Last Button after Ending Breakingpoint Button-->
    <PaginationButton
      v-if="lastButtonIfCondition"
      :page-number="totalPages"
      :total-pages="totalPages"
    >
      {{ totalPages }}
    </PaginationButton>

    <!-- Next Button -->
    <PaginationButton
      :is-disabled="currentPage === totalPages"
      :page-number="currentPage + 1"
      :total-pages="totalPages"
    >
      <ArrowRightIcon class="size-4" />
    </PaginationButton>
  </ul>
</template>
