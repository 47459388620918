<script setup lang="ts">
import { SearchParams } from '../../../config/search-params';

withDefaults(defineProps<{
  isActive?: boolean;
  isClickable?: boolean;
  isDisabled?: boolean;
  pageNumber: number;
  totalPages: number;
}>(), { isActive: false,isClickable: true,isDisabled: false, })

const {
  currentPage,
  setCurrentPage,
} = usePagination();

const route = useRoute();

const onClickHandler = () => {
  if (__props.pageNumber === currentPage.value || __props.pageNumber > __props.totalPages || __props.pageNumber < 1) {
    return;
  }

  setCurrentPage(__props.pageNumber);
  window.scroll(0, 0);
};

const navigationHandler = (): string => {
  const params = new URLSearchParams(route.query as Record<string, string>);
  params.set(SearchParams.Page, __props.pageNumber.toString());

  return `${route.path}?${params.toString()}`;
};
</script>

<template>
  <li class="group first-of-type:mr-1 last-of-type:ml-1 md:first-of-type:mr-4 md:last-of-type:ml-4">
    <a
      class="flex h-8 min-w-8 items-center justify-center rounded-full px-2.5 text-xs group-first-of-type:p-0 group-first-of-type:hover:bg-dark group-first-of-type:hover:text-white group-last-of-type:p-0 group-last-of-type:hover:bg-dark group-last-of-type:hover:text-white md:h-10 md:min-w-10 md:text-base"
      :class="[
        isClickable ? 'border-2 border-grey hover:border-dark group-first-of-type:border-dark group-last-of-type:border-dark': 'pointer-events-none cursor-default',
        {
          '!border-dark bg-dark text-white': isActive,
          'pointer-events-none cursor-default border-2 !border-grey-light text-grey-light': isDisabled,
        },
      ]"
      :href="navigationHandler()"
      @click.prevent="onClickHandler"
    >
      <slot />
    </a>
  </li>
</template>
