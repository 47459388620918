<script setup lang="ts">
import type {
  BaseFilter,
  Filter,
  FilterCategoryName,
  NestedFilter,
} from '@/types/list-page';

withDefaults(defineProps<{
  filter: NestedFilter | BaseFilter;
  groupTitle: FilterCategoryName;
  isParentPartlySelected?: boolean;
}>(), {  })

const { t } = useI18n();

const { toggleActiveFilters } = useActiveFilters();

const textClasses = computed(() => ({
  '!text-grey': !!__props.filter.isDisabled,
  'font-medium': !!__props.filter.isSelected,
}));

const onClickFilter = (filterItem: Filter, isSelected: boolean) => {
  if (filterItem.isDisabled) {
    return;
  }

  toggleActiveFilters({
    filterCategoryName: __props.groupTitle,
    filterKey: filterItem.key,
    wasFilterSelected: isSelected,
  });
};

const label = computed(() => {
  if (__props.filter.label) {
    return __props.filter.label;
  }

  if (__props.groupTitle !== 'product') {
    return t(`designListing.filter.${__props.groupTitle}.${__props.filter.key}`);
  }

  return isNestedFilter(__props.filter)
    ? t(`product.category.${__props.filter.key}`)
    : t(`product.type.${__props.filter.key}`);
});
</script>

<template>
  <div
    class="filter-item group/checkbox flex w-full items-center text-sm text-dark hover:text-semidark"
    @click="onClickFilter(filter, !!filter.isSelected)"
  >
    <UiCheckbox
      :id="filter.key"
      :is-checked="!!filter.isSelected"
      :is-disabled="!!filter.isDisabled"
      :is-partly-selected="!!filter.isSelected && !isParentPartlySelected"
    />
    <span
      class="ml-2.5"
      :class="textClasses"
    >
      {{ label }}
    </span>
  </div>
</template>
