<script setup lang="ts">
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from 'lucide-vue-next';
import type { FilterGroup } from '@/types/list-page';

interface Props {
  filterGroup: FilterGroup;
  isExpanded: boolean;
}

const { t } = useI18n();

withDefaults(defineProps<Props>(), {  })

const label = t(`designListing.filter.${__props.filterGroup.key}.title`);

defineEmits<{
  toggleExpanded: [];
}>();
</script>

<template>
  <div
    class="filter-group-headline"
    data-test="filter-group-headline"
    @click="$emit('toggleExpanded')"
  >
    <div class="flex">
      <span class="text-base font-medium">{{ label }}</span>
    </div>
    <component
      :is="isExpanded ? ChevronUpIcon : ChevronDownIcon"
      class="size-4"
    />
  </div>
</template>

<style lang="scss" scoped>
.filter-group-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  cursor: pointer;
}
</style>
