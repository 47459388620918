<script setup lang="ts">
import {
  CircleMinusIcon,
  CirclePlusIcon,
} from 'lucide-vue-next';

const { t } = useI18n();

withDefaults(defineProps<{ showAll: boolean }>(), {  })

defineEmits<{
  toggleShowAll: [];
}>();

const translationKeys = {
  showAll: 'designListing.showAll',
  showLess: 'designListing.showLess',
};

const showAllOrLessLabel = computed(() => t(__props.showAll
  ? translationKeys.showLess
  : translationKeys.showAll));
</script>

<template>
  <div
    class="show-all-or-less -ml-0.5 flex cursor-pointer items-center text-dark hover:text-semidark"
    @click="$emit('toggleShowAll')"
  >
    <component
      :is="showAll ? CircleMinusIcon : CirclePlusIcon"
      class="size-6"
    />
    <span
      class="ml-1.5 text-sm text-dark"
      v-text="showAllOrLessLabel"
    />
  </div>
</template>
