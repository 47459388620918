<script setup lang="ts">
import { useModalVisible } from '@/composables/useModalVisible';

const { setIsModalVisible } = useModalVisible();

const {
  areAllFiltersEmpty,
  getCurrentFilterGroups,
  setIsFiltersListHidden,
} = useActiveFilters();

const filterOverlay = ref(null);

const translationKeys = { applyFilters: 'designListing.applyFilters' };

useIntersectionObserver(
  filterOverlay,
  ([ { isIntersecting } ]) => {
    setIsModalVisible(isIntersecting);
  },
);

onMounted(() => {
  setIsModalVisible(true);
});

onUnmounted(() => {
  setIsModalVisible(false);
});
</script>

<template>
  <div
    ref="filterOverlay"
    class="filter-groups-overlay z-50"
  >
    <div class="filter-groups-overlay-content">
      <FilterHeader class="filter-groups-overlay-headline" />
      <SelectedFilterBar
        v-if="!areAllFiltersEmpty"
        class="filter-groups-overlay-selected-filters"
      />
      <FilterGroup
        v-for="(filterGroup) in getCurrentFilterGroups"
        :key="filterGroup.key"
        :filter-group="filterGroup"
      />
      <UiButtonLink
        class="filter-groups-overlay-apply-filters"
        :text="$t(translationKeys.applyFilters)"
        :url="'#design-listing-component'"
        @click="setIsFiltersListHidden(true)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$padding: 25px;

.filter-groups-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  background-color: var(--grey-semidark);

  &-content {
    height: 100%;
    padding: $padding $padding 4 * $padding;
    overflow: scroll;
    background-color: var(--white);
    border-radius: $border-radius $border-radius 0;
  }

  &-headline {
    margin: 0 0 30px;
  }

  &-selected-filters {
    margin-bottom: 30px;
  }

  &-apply-filters.button {
    position: fixed;
    bottom: $padding;
    width: calc(100% - 30px);
    height: 40px;
    margin: 0 -10px -10px;
  }
}

@include bp-desktop {
  .filter-groups-overlay {
    display: none;
  }
}
</style>
