<script setup lang="ts">
import { XIcon } from 'lucide-vue-next';
import {
  DESIGN_TYPE,
  SEARCH_PARAM,
} from '@/config/filter';
import type { FilterCategoryName } from '@/types/list-page';

const {
  t,
  te,
} = useI18n();

const {
  getCurrentFilterGroups,
  toggleActiveFilters,
} = useActiveFilters();

withDefaults(defineProps<{
  filterKey: string;
  groupTitle: FilterCategoryName | typeof DESIGN_TYPE | typeof SEARCH_PARAM;
}>(), {  })

const label = computed(() => {
  if (__props.groupTitle === 'q') {
    return __props.filterKey;
  }

  if (__props.groupTitle === 'product') {
    return te(`product.category.${__props.filterKey}`)
      ? t(`product.category.${__props.filterKey}`)
      : t(`product.type.${__props.filterKey}`);
  }

  if (__props.groupTitle === 'designType') {
    return t(`designListing.filter.${__props.groupTitle}.${__props.filterKey.toLocaleLowerCase()}`);
  }

  return findFilterInGroup(getCurrentFilterGroups.value, __props.groupTitle, __props.filterKey)?.label || t(`designListing.filter.${__props.groupTitle}.${__props.filterKey}`);
});

const isColorFilter = computed(() => [
  'designColor',
  'productColor',
].includes(__props.groupTitle));
</script>

<template>
  <div class="selected-filter-bar-item mr-2.5 flex w-min items-center whitespace-nowrap rounded-2xl bg-grey-light px-2.5 py-1 text-xs font-medium no-underline">
    <span
      v-if="isColorFilter"
      class="block size-4 rounded-full"
      :style="{ backgroundColor: `#${filterKey.replace('#', '')}` }"
    />
    <span v-else>{{ label }}</span>
    <button
      class="-my-0.5 -mr-1.5 ml-1.5 flex size-5 items-center justify-center rounded-full hover:bg-grey"
      @click.prevent="toggleActiveFilters({ filterKey: filterKey, filterCategoryName: groupTitle, wasFilterSelected: true })"
    >
      <XIcon class="size-3" />
    </button>
  </div>
</template>
