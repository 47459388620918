<script setup lang="ts" generic="T extends string">
import { ChevronDownIcon } from 'lucide-vue-next';
import type { ButtonVariants } from '../button/variants';

withDefaults(defineProps<{
  buttonSize?: ButtonVariants['size'];
  buttonVariant?: ButtonVariants['variant'];
  icon?: Component;
  iconPosition?: 'left' | 'right';
  modelValue?: T;
  options: {
    label: string;
    value: T;
  }[];
}>(), { buttonVariant: 'secondary',iconPosition: 'right', })

const emit = defineEmits<{
  'update:modelValue': [ value: T ];
}>();

const getLabelForOption = (optionValue: T) => {
  const optionObject = __props.options.find((option) => option.value === optionValue);
  return optionObject?.label;
};

const model = computed({
  get: () => __props.modelValue,
  set: (value) => value && emit('update:modelValue', value),
});
</script>

<template>
  <DropdownMenu :modal="false">
    <DropdownMenuTrigger as-child>
      <Button
        class="max-w-full"
        :size="buttonSize"
        :variant="buttonVariant"
      >
        <component
          :is="icon"
          v-if="icon && iconPosition === 'left'"
          aria-hidden="true"
          class="size-5"
        />
        <span class="truncate">{{ getLabelForOption(modelValue as T) }}</span>
        <component
          :is="icon && iconPosition === 'right' ? icon : ChevronDownIcon"
          aria-hidden="true"
          class="size-4"
        />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent>
      <DropdownMenuRadioGroup v-model="model">
        <DropdownMenuRadioItem
          v-for="option in options"
          :key="option.value"
          :data-cy="`dropdown-item-${option.label}`"
          :value="String(option.value)"
        >
          {{ option.label }}
        </DropdownMenuRadioItem>
      </DropdownMenuRadioGroup>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
