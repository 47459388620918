import type { AlgoliaFilterFacetGroup } from 'shop-frontend/graphql-bff';

interface Facet {
  key: string;
  value: string[];
}

interface FilterFacetGroup {
  key: string;
  value: Facet[];
}

/**
 * sort all the filters alphabetically to avoid double cache-entries when filters
 * got activated in a different order
 * @param facetGroups
 */
const sortedFilterFacetGroups = (facetGroups: AlgoliaFilterFacetGroup[]): FilterFacetGroup[] => facetGroups
  .map((facetGroup) => ({
    ...facetGroup,
    value: facetGroup.value
      .map((facet) => ({
        ...facet,
        value: Array.from(new Set(facet.value)).sort(),
      }))
      .sort((a, b) => a.value[0].localeCompare(b.value[0])),
  }))
  .sort((a, b) => a.key.localeCompare(b.key));

export { sortedFilterFacetGroups };
