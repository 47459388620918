<script setup lang="ts">
withDefaults(defineProps<{
  numberOfFilterResults: number;
}>(), {  })

const { n } = useI18n();

const numberOfHits = computed(() => n(__props.numberOfFilterResults));

const translationKeys = { numberOfDesigns: 'designListing.numberOfDesigns' };
</script>

<template>
  <div class="design-type-filters col-span-2 col-start-1 flex w-full items-center justify-between whitespace-nowrap md:items-start md:pt-2">
    <div class="hidden w-[100px] flex-none text-sm md:block">
      {{ $t(translationKeys.numberOfDesigns, { number: numberOfHits }) }}
    </div>
    <DesignTypeFilter />
    <SortingSelection />
  </div>
</template>
