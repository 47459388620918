<script setup lang="ts">
import { isEqual } from 'lodash-es';

const {
  getAreInitialFiltersSetViaSearchParams,
  getPreselectedFilters,
} = usePreselectedFilters();

const {
  getActiveFilters,
  setActiveFilters,
  updateCurrentFilterGroupsAfterFilterWasToggled,
  updateSearchParams,
} = useActiveFilters();

const translationKeys = { reset: 'designListing.resetFilters' };

const isResetDisabled = computed(() => isEqual(getPreselectedFilters.value, getActiveFilters.value));

const resetFilter = () => {
  setActiveFilters(getPreselectedFilters.value);
  updateCurrentFilterGroupsAfterFilterWasToggled();
  updateSearchParams(!getAreInitialFiltersSetViaSearchParams.value);
};
</script>

<template>
  <button
    class="reset-filters solid relative flex h-[30px]  items-center justify-center whitespace-nowrap rounded-2xl border border-solid border-grey px-2.5 text-xs md:ml-7 md:h-10 md:rounded-[20px]"
    type="button"
    :class="[isResetDisabled ? 'pointer-events-none cursor-default text-grey' : 'cursor-pointer text-dark hover:border-2 hover:border-dark hover:px-[9px]']"
    :disabled="isResetDisabled"
    @click="resetFilter"
  >
    <ResetIcon class="icon size-5" />
    <span
      class="label hidden md:ml-2 md:inline-block"
      v-text="$t(translationKeys.reset)"
    />
  </button>
</template>
