<script setup lang="ts">
import type {
  BaseFilter,
  Filter,
  FilterCategoryName,
} from '@/types/list-page';

withDefaults(defineProps<{
  allFiltersInGroup: BaseFilter[];
  currentFilter: BaseFilter;
  groupTitle: FilterCategoryName;
}>(), {  })

const { t } = useI18n();

const { toggleActiveFilters } = useActiveFilters();

const label = computed(() => (__props.currentFilter.label ?? t(`designListing.filter.${__props.groupTitle}.${__props.currentFilter.key}`)));

const onClickFilter = (filterItem: Filter, isSelected: boolean) => {
  // Deselect all filters in that group if they are selected
  __props.allFiltersInGroup.forEach((filter) => {
    if (filter.isSelected && filter.key !== filterItem.key) {
      toggleActiveFilters({
        filterCategoryName: __props.groupTitle,
        filterKey: filter.key,
        wasFilterSelected: false,
      });
    }
  });

  toggleActiveFilters({
    filterCategoryName: __props.groupTitle,
    filterKey: filterItem.key,
    wasFilterSelected: isSelected,
  });
};
</script>

<template>
  <div
    class="filter-item group/checkbox flex w-full items-center text-sm text-dark hover:text-semidark"
    @click="onClickFilter(currentFilter, !!currentFilter.isSelected)"
  >
    <UiRadioButton
      :id="currentFilter.key"
      :is-checked="!!currentFilter.isSelected"
      :is-disabled="currentFilter.isDisabled"
    />
    <div
      class="ml-2.5 hover:cursor-pointer"
      :class="{ 'font-medium': currentFilter.isSelected }"
    >
      {{ label }}
    </div>
  </div>
</template>
