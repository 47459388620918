<script setup lang="ts">
import { InfoIcon } from 'lucide-vue-next';
import { DESIGN_TYPE_FILTER_TABS } from '@/config';
import { DESIGN_TYPE } from '@/config/filter';
import type { DesignType } from '#gql/default';

const DESKTOP_BREAKPOINT = 800;
const OFFSET_DESKTOP = 20;
const OFFSET_MOBILE = 12;

const {
  getSelectedDesignType,
  setSelectedDesignType,
} = useActiveFilters();

const router = useRouter();

const route = useRoute();

const translationKeys = { tabPrefix: 'designListing.' };

const { isMobile } = useAppBreakpoints();

const { width: screenWidth } = useWindowSize({
  initialWidth: isMobile
    ? 0
    : DESKTOP_BREAKPOINT,
});

const tabOffset = computed(() => ((screenWidth.value >= DESKTOP_BREAKPOINT)
  ? OFFSET_DESKTOP
  : OFFSET_MOBILE));

const {
  closeDialog,
  isDialogOpen,
  openDialog,
} = useDialog();

const {
  onClick,
  onMouseEnter,
  onMouseLeave,
  tabIndicatorStyle,
  tabsRef,
  uiTabsClass,
  updateActiveElement,
  updateUseTransition,
} = useTabIndicator(tabOffset);

const onSelectDesignType = (
  $event: MouseEvent,
  tabKey: DesignType,
) => {
  setSelectedDesignType(tabKey);

  let query = { ...route.query };

  if (tabKey) {
    query = {
      ...query,
      [DESIGN_TYPE]: tabKey,
    };
  } else {
    delete query[DESIGN_TYPE];
  }

  delete query.page;

  router.replace({ query });
  onClick($event);
};

const { width: tabContainerWidth } = useElementSize(tabsRef);

watch(tabContainerWidth, () => {
  updateActiveElement();
});
</script>

<template>
  <div
    ref="tabsRef"
    class="design-type-filter relative top-0.5 -mt-1 justify-self-center"
    data-cy="design-type-filter"
    :class="uiTabsClass"
    @mouseenter="updateUseTransition(true)"
    @mouseleave="updateUseTransition(false)"
  >
    <a
      v-for="tab in DESIGN_TYPE_FILTER_TABS"
      :key="tab.name"
      class="filter-tab -ml-3 inline-flex cursor-pointer items-center justify-center border border-transparent px-3 pb-0.5 pt-1.5 text-sm font-normal md:ml-0 md:pt-0 md:text-base md:font-medium ml:px-5"
      :class="{ 'filter-tab-active text-sm !font-medium md:text-base md:!font-semibold': tab.filterkey === getSelectedDesignType }"
      @click="onSelectDesignType($event, tab.filterkey)"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <span v-text="$t(`${translationKeys.tabPrefix}${tab.name}`)" />
      <InfoIcon
        v-if="tab.showInformationIcon"
        class="ml-1 size-4"
        @click.prevent="openDialog"
        @click.stop
      />
      <DesignTypeDialog
        :close-dialog="closeDialog"
        :is-dialog-open="isDialogOpen"
      />
    </a>
    <div
      class="transition-tab pointer-events-none absolute bottom-0 h-0.5 rounded-t-sm bg-dark"
      :style="tabIndicatorStyle"
    />
  </div>
</template>
