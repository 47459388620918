import type { Filter } from '@/types/list-page';

const useProductColorFilter = () => {
  const {
    getActiveFilters,
    getCurrentFilterGroups,
    toggleActiveFilters,
  } = useActiveFilters();

  const getProductColorFilterOptions = computed(() => getCurrentFilterGroups.value
    .find((filterGroup) => filterGroup.key === 'productColor')?.filters ?? []);

  const setActiveProductColorFilterOption = (option: Filter) => {
    const activeProductColor = getActiveFilters.value?.productColor?.[0];
    const wasNewColorActive = activeProductColor === option.key;

    if (activeProductColor && !wasNewColorActive) {
      toggleActiveFilters({
        filterCategoryName: 'productColor',
        filterKey: activeProductColor,
        wasFilterSelected: true,
      });
    }
    toggleActiveFilters({
      filterCategoryName: 'productColor',
      filterKey: option.key,
      wasFilterSelected: wasNewColorActive,
    });
  };

  return {
    getProductColorFilterOptions,
    setActiveProductColorFilterOption,
  };
};

export { useProductColorFilter };
