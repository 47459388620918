<script setup lang="ts">
import { Color } from '@/types/common';
import type {
  BaseFilter,
  FilterCategoryName,
} from '@/types/list-page';

interface Props {
  filter: BaseFilter;
  groupTitle: FilterCategoryName;
}

withDefaults(defineProps<Props>(), {  })
const { toggleActiveFilters } = useActiveFilters();
</script>

<template>
  <div
    class="color-filter-item group/checkbox"
    href="#"
  >
    <UiCheckbox
      :id="filter.key"
      class="size-8 justify-self-start"
      :check-mark-color="getComplementaryColor(filter.key)"
      :class="{ 'border-none': getComplementaryColor(filter.key) === Color.WHITE }"
      :is-checked="!!filter.isSelected"
      :style="{ backgroundColor: `#${filter.key}` }"
      @click="toggleActiveFilters({ filterCategoryName: groupTitle, filterKey: filter.key, wasFilterSelected: !!filter.isSelected })"
    />
  </div>
</template>
