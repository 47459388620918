<template>
  <svg
    height="14"
    viewBox="0 0 18.499 14"
    width="18.499"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="icon-reset"
      transform="translate(0 19.5) rotate(-90)"
    >
      <path
        id="Pfad_801"
        d="M11.081,4.235v.008c-2.213-.023.994-.012-2.59-.009L11,1.737A1.014,1.014,0,0,0,11,.3,1.027,1.027,0,0,0,9.555.3L5.3,4.529a1.026,1.026,0,0,0-.07.077A1.014,1.014,0,0,0,5.3,5.969L9.555,10.2A1.021,1.021,0,1,0,11,8.761L8.5,6.277c3.555,0,.474,0,2.625,0l1-.008a4.846,4.846,0,1,1-.049,9.692,1.018,1.018,0,1,0,0,2.036,6.882,6.882,0,1,0,0-13.764"
        data-name="Pfad 801"
        fill="currentColor"
        transform="translate(0.5 0.001)"
      />
      <path
        id="Pfad_802"
        d="M136.419,320.4a1.5,1.5,0,1,0,1.5,1.5,1.5,1.5,0,0,0-1.5-1.5"
        data-name="Pfad 802"
        fill="currentColor"
        transform="translate(-128.419 -304.898)"
      />
    </g>
  </svg>
</template>
