<script setup lang="ts">
import { DesignType } from '#gql/default';
import {
  DESIGN_TYPE,
  SEARCH_PARAM,
} from '@/config/filter';
import type {
  FilterCategoryName,
  FilterCategoryWithFacets,
} from '@/types/list-page';

const {
  getActiveFilters,
  getSelectedDesignType,
} = useActiveFilters();

const route = useRoute();
const queryParam = computed(() => route.query.q?.toString() || null);

const getFilterTitleTranslationKey = (filterKey: string) => `designListing.filter.${filterKey}.title`;

const translationKeys = {
  designType: 'common.designType',
  search: 'common.search',
};

const relevantFilters = computed(() => Object.entries(getActiveFilters.value).reduce((acc, [
  key,
  value,
]) => {
  if (value.length) {
    acc[key as FilterCategoryName] = value;
  }
  return acc;
}, {} as FilterCategoryWithFacets));

const hasRelevantFilters = computed(() => relevantFilters.value
    && typeof relevantFilters.value === 'object'
    && Object.keys(relevantFilters.value || {}).length > 0);
</script>

<template>
  <div
    v-if="hasRelevantFilters"
    class="selected-filter-bar flex items-center bg-white"
  >
    <UiScroller
      has-full-height-buttons
      never-hide-buttons
    >
      <template #scrollerContent>
        <div class="selected-filter-bar-wrapper flex flex-nowrap justify-start">
          <PseudoFilter
            :filter="queryParam"
            :group-title="SEARCH_PARAM"
            :translation-key="translationKeys.search"
          />
          <PseudoFilter
            v-if="getSelectedDesignType !== DesignType.ALL"
            :filter="getSelectedDesignType"
            :group-title="DESIGN_TYPE"
            :translation-key="translationKeys.designType"
          />
          <div
            v-for="[activeFilterKey, activeFilters] in Object.entries(relevantFilters)"
            :key="activeFilterKey"
            class="filter-group md:mr-5"
          >
            <span
              class="filter-group-headline mb-1 mt-0 hidden px-2 text-xxs font-medium text-grey-dark md:block"
              v-text="$t(getFilterTitleTranslationKey(activeFilterKey))"
            />
            <div class="filter-group-items flex">
              <SelectedFilterBarItem
                v-for="filter in activeFilters"
                :key="filter"
                :filter-key="filter"
                :group-title="(activeFilterKey as FilterCategoryName)"
              />
            </div>
          </div>
        </div>
      </template>
    </UiScroller>
    <SelectedFilterBarResetButton />
  </div>
</template>
