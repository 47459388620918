<script setup lang="ts">
import type { BaseFilter } from '@/types/list-page';

defineProps<{
  filters: BaseFilter[];
}>();

const translationKeys = { color: 'product.color.' };

const { setActiveProductColorFilterOption } = useProductColorFilter();
const { getActiveFilters } = useActiveFilters();
</script>

<template>
  <div class="grid grid-cols-4 items-start gap-y-5">
    <div
      v-for="(filterOption) in filters.filter(option => !option.isDisabled)"
      :key="filterOption.key"
      class="flex justify-start"
    >
      <div
        :class="[
          'ring:offset-transparent size-8 shrink-0 cursor-pointer rounded-full border border-grey-mid ring-offset-4',
          getActiveFilters.productColor.includes(filterOption.key) ? 'ring-2 ring-purple' : 'hover:ring-1 hover:ring-purple',
        ]"
        :style="{
          backgroundColor: `#${filterOption.key}`,
        }"
        :title="$t(translationKeys.color + filterOption.key)"
        @click="setActiveProductColorFilterOption(filterOption)"
      />
    </div>
  </div>
</template>
