const useShowAllOrLessFilters = () => {
  const showAll = ref(false);

  const onToggleShowAll = () => {
    showAll.value = !showAll.value;
  };

  return {
    onToggleShowAll,
    showAll,
  };
};

export { useShowAllOrLessFilters };
