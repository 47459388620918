<script setup lang="ts">
import { ArrowDownUpIcon } from 'lucide-vue-next';
import {
  sortingIndex,
  type SortingIndex,
} from '@/config/designs';

const {
  getSorting,
  updateSorting,
} = useSortingParameter();

const { t } = useI18n();

const { y } = useWindowScroll();

const showDropdown = ref(false);

const toggleButton = ref<HTMLDivElement>();

const toggleDropdown = (showDropdownParam: boolean) => {
  showDropdown.value = showDropdownParam;
};

onClickOutside(toggleButton, () => toggleDropdown(false));

const onSelectOption = (optionValue: string) => {
  updateSorting(optionValue as SortingIndex);
  toggleDropdown(false);
};

watch(y, () => {
  toggleDropdown(false);
});

const sortingOptions = computed(() => sortingIndex
  .map((value) => ({
    label: t(`common.${value}`),
    value,
  })));
</script>

<template>
  <UiDropdown
    button-size="small"
    button-variant="ghost"
    :icon="ArrowDownUpIcon"
    :model-value="getSorting"
    :options="sortingOptions"
    @update:model-value="onSelectOption"
  />
</template>
@/config/designs
