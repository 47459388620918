<script setup lang="ts">
import { AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN } from '@/config/filter';
import type { FilterGroup } from '@/types/list-page';

withDefaults(defineProps<{
  filterGroup: FilterGroup;
}>(), {  })

const {
  onToggleShowAll,
  showAll,
} = useShowAllOrLessFilters();

const isExpanded = ref(__props.filterGroup.isExpanded);

const onToggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
};

const isColorFiltersGroup = computed(() => __props.filterGroup.key === 'designColor');
const isProductColorFilterGroup = computed(() => __props.filterGroup.key === 'productColor');

const isShowAllVisible = computed(() => !isColorFiltersGroup.value
  && !isProductColorFilterGroup.value
  && __props.filterGroup.filters.length > AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN);

const filters = computed(() => (showAll.value
  || isColorFiltersGroup.value
  || isProductColorFilterGroup.value
  ? __props.filterGroup.filters
  : Object.values(__props.filterGroup.filters).slice(0, AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN)));
</script>

<template>
  <div
    v-if="!filterGroup.isHidden"
    class="filter-group w-full"
    :class="{ expanded: isExpanded }"
  >
    <FilterGroupHeadline
      :filter-group="filterGroup"
      :is-expanded="isExpanded || false"
      @toggle-expanded="onToggleExpanded"
    />
    <div
      v-if="isExpanded"
      data-test="expandable-filters"
    >
      <ColorFilterList
        v-if="isColorFiltersGroup"
        :filters="filters"
        :group-title="filterGroup.key"
      />
      <ProductColorFilter
        v-else-if="isProductColorFilterGroup"
        :filters="filters"
        :group-title="filterGroup.key"
      />
      <FilterList
        v-else
        :filters="filters"
        :group-title="filterGroup.key"
      />
      <ShowAllOrLess
        v-if="isShowAllVisible"
        class="mt-5"
        :show-all="showAll"
        @toggle-show-all="onToggleShowAll"
      />
    </div>
  </div>
</template>
