<script setup lang="ts">
import {
  FilterIcon,
  XIcon,
} from 'lucide-vue-next';

const { setIsFiltersListHidden } = useActiveFilters();

const translationKeys = { filters: 'designListing.filters' };
</script>

<template>
  <header class="filter-header flex items-center justify-start text-base font-medium">
    <FilterIcon class="mr-2.5 size-5" />
    <p
      class="m-0"
      v-text="$t(translationKeys.filters)"
    />
    <button
      class="close-button"
      type="button"
      @click="setIsFiltersListHidden(true)"
    >
      <XIcon class="max-h-full w-4 text-white" />
    </button>
  </header>
</template>

<style lang="scss" scoped>
.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: auto;
  cursor: pointer;
  background: var(--dark);
  border: unset;
  border-radius: $border-radius-50;
}
</style>
